<template>
  <div class="productInfo">
    <div class="producBox">
      <!-- 产品信息 -->
      <div class="infoBox">
        <p class="infoBoxTitle">{{ $t.detailsInterpret.productInfo }}</p>
        <section class="info">
          <p class="infoLeft">{{ $t.detailsInterpret.weight }}</p>
          <p class="infoRight">{{ productInfo.Weight }}Kg</p>
        </section>
        <section class="info">
          <p class="infoLeft">{{ $t.detailsInterpret.bulk }}</p>
          <p class="infoRight">{{ productInfo.Volume }}cbm</p>
        </section>
      </div>
      <section class="productDesc" v-if="!productInfo.TDescriptions">
        <h3 class="desc">{{ $t.detailsInterpret.productDescription }}</h3>
        <p class="descInfo" v-if="productInfo.Descriptions">
          {{ productInfo.Descriptions }}
        </p>
        <p class="descInfo" v-else>{{ $t.detailsInterpret.none }}</p>
      </section>
      <section class="productDesc" v-else>
        <h3 class="desc">{{ $t.detailsInterpret.productDescription }}</h3>
        <p class="descInfo" v-if="productInfo.TDescriptions">
          {{ productInfo.TDescriptions }}
        </p>
        <p class="descInfo" v-else>{{ $t.detailsInterpret.none }}</p>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductInfo",
  props: ["productInfo"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.productInfo {
  width: 100%;
  margin-top: 10px;
  .producBox {
    margin: 0 auto;
    background-color: #ffffff;
    padding: 22px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    width: 92%;
    .infoBox {
      .infoBoxTitle {
        font-size: 15px;
        color: #000000;
        padding: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-bottom: 3px;
      }
      .info {
        margin-top: 14px;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        font-size: 14px;
        color: #666666;
        @include publicFlex;
        .infoLeft {
          margin: 0px;
          padding: 0px;
        }
        .infoRight {
          margin: 0px;
          padding: 0px;
        }
      }
    }
    .productDesc {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #eaeaea;
      .desc {
        margin: 0;
        padding: 0;
        font-size: 15px;
        color: #000000;
        font-weight: normal;
      }
      .descInfo {
        margin: 0;
        padding: 0;
        font-size: 13px;
        margin-top: 18px;
        color: #666666;
      }
    }
  }
}
</style>
