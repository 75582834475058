<template>
  <div class="purchase">
    <div class="title">{{ $t.detailsInterpret.quantity }}</div>
    <section class="right">
      <p class="minus" @click="minus">-</p>
      <p class="num">{{ newSellNumber }}</p>
      <p class="plus" @click="plus">+</p>
    </section>
  </div>
</template>
<script>
export default {
  name: "Purchase",
  props: ["sellNumber", "inventories"],
  data() {
    return {
      oldSellNumber: this.sellNumber === 0 ? 1 : this.sellNumber,
      newSellNumber: this.sellNumber === 0 ? 1 : this.sellNumber
    };
  },
  watch: {
    sellNumber() {
      this.oldSellNumber = this.sellNumber === 0 ? 1 : this.sellNumber;
      this.newSellNumber = this.sellNumber === 0 ? 1 : this.sellNumber;
      // console.log(this.newSellNumber)
    }
  },
  mounted() {
    this.$bus.$off("resetPurchase").$on("resetPurchase", () => {
      this.newSellNumber = this.sellNumber === 0 ? 1 : this.sellNumber;
    });
  },
  methods: {
    minus() {
      let newSellNumber = this.newSellNumber - 1;
      if (newSellNumber >= this.oldSellNumber) {
        this.newSellNumber--;
      } else {
        this.$commonMethod.showNotify({
          type: "error",
          titleMsg: this.$t.detailsInterpret.upSold(this.oldSellNumber)
        });
      }
    },
    plus() {
      if (this.newSellNumber >= this.inventories) {
        this.$commonMethod.showNotify({
          type: "error",
          titleMsg: this.$t.detailsInterpret.inventoryLimit
        });
      } else {
        this.newSellNumber++;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.purchase {
  width: 88%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  @include publicFlex;
  * {
    margin: 0;
    padding: 0;
  }
  .title {
    font-size: 14px;
    color: #000000;
  }
  .right {
    @include publicFlex;
    .minus {
      min-width: 25px;
      color: #999999;
      background-color: #f6f6f6;
      border-radius: 4px;
      padding: 10px 0;
      text-align: center;
      width: 40px;
      font-size: 18px;
      font-weight: bold;
    }
    .num {
      min-width: 43px;
      font-size: 14px;
      color: #000000;
      text-align: center;
      border-radius: 4px;
      background-color: #ffffff;
      padding: 10px 0;
      border: 1px solid #cdcdcd;
      margin: 0 5px;
    }
    .plus {
      min-width: 25px;
      color: #999999;
      background-color: #f6f6f6;
      border-radius: 4px;
      padding: 10px 0;
      text-align: center;
      width: 40px;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
