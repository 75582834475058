<template>
  <div class="specBox">
    <div class="spec">
      <ul class="list">
        <li
          class="li"
          v-for="(item, index) in productInfo.ListClassModels"
          :key="index"
        >
          <p class="liTitle">{{ item.ModelName }}</p>
          <div class="liItem">
            <span
              class="item"
              v-for="(newItem, newIndex) in item.SonClassModels"
              :key="newIndex"
              >{{ newIndex !== 0 ? "/" : "" }}{{ newItem.ModelName }}</span
            >
          </div>
        </li>
        <!-- <li class="li">
          <p class="liTitle">产品颜色</p>
          <div class="liItem">
            <span class="item">白色/</span>
            <span class="item">白色/</span>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Spec",
  props: ["productInfo"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.specBox {
  margin: 0 auto;
  width: 92%;
  .spec {
    width: 100%;
    border-radius: 8px;
    padding: 6px 22px;
    padding-bottom: 23px;
    background-color: #ffffff;
    margin-top: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .list {
      list-style: none;
      margin: 0;
      padding: 0;
      list-style: none;
      .li {
        margin: 0;
        padding: 0;
        list-style: none;
        align-items: center;
        -webkit-align-items: center;
        width: 100%;
        margin-top: 17px;
        @include publicFlex;
        .liTitle {
          font-size: 14px;
          color: #43cd9a;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 10px;
        }
        .liItem {
          text-align: left;
          font-size: 14px;
          color: #000000;
          max-width: 80%;
          .item {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
