<template>
  <div class="skuList" v-if="productModelPrice">
    <ul class="listBox">
      <li class="itemBox" v-for="(item,index) in listClassModels" :key="item.PCModelId">
        <p class="itemTitle">{{item.ModelName}}</p>
        <section class="specBox">
          <div :class="['specItem',isModelPriceSku(newItem.PCModelId)?'specItemAction':'']"
          @click="selectItem(item,newItem.PCModelId,index)"
          v-for="newItem in item.SonClassModels"
          :key="newItem.PCModelId">{{newItem.ModelName}}</div>
        </section>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'SkuList',
  props: ['listClassModels', 'productModelPrice', 'listViewProductModelPriceOrIMG'],
  data () {
    return {
      newProductModelPrice: this.productModelPrice
    }
  },
  watch: {
    productModelPrice () {
      this.newProductModelPrice = this.productModelPrice
    }
  },
  computed: {
  },
  methods: {
    // 判断当前价格的sku是否存在与sku集合类
    isModelPriceSku (pCModelId) {
      let pCModelIdList = this.newProductModelPrice.PCModelId
      return pCModelIdList.indexOf(pCModelId) !== -1
    },
    // 选择类型
    selectItem (item, pCModelId, index) {
      this.$emit('selectItem', item, pCModelId, index)
    },
    // 判断规格组合只有一个还是多个
    isModelPrice () {
      let pcModelId = this.productModelPrice.PCModelId
      // let reg = RegExp(/|/)
      // console.log(pcModelId.indexOf('|'))
      if (pcModelId.indexOf('|') !== -1) {
        // console.log(this.productModelPrice.PCModelId.split('|'))
        return this.productModelPrice.PCModelId.split('|')
      } else {
        let pcModel = []
        pcModel[0] = this.productModelPrice.PCModelId
        return pcModel
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.skuList {
  width: 88%;
  margin: 0 auto;
  // padding-bottom: 40px;
  min-height: 100px;
  * {
    margin: 0;
    padding: 0;
  }
  .listBox {
    width: 100%;
    list-style: none;
    
    .itemBox {
      margin: 0;
      padding: 0;
      list-style: none;
      margin-top: 20px;
      margin-bottom: 6px;
      .itemTitle {
        font-size: 14px;
        color: #000000;
      }
      .specBox {
        width: 100%;
        //display: flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        @include publicFlex;
        .specItem {
          min-width: 20.9%;
          border-radius: 5px;
          background-color: #f6f6f6;
          color: #aeaeae;
          font-size: 12px;
          text-align: center;
          padding: 8px 5px;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          margin-right: 4.1%;
          margin-top: 9px;
          &:nth-child(1){
            margin-left: 0;
            margin-right: 4.1%;
          }
        }
        .specItemAction {
          background-color: #48d58e;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
