<template>
  <div class="skuDiv">
    <div class="sku">
      <transition name="obscurationClass" @touchmove.prevent>
        <div class="obscuration" v-if="showSku" @click="hideSku"></div>
      </transition>
      <transition name="fullscreen">
        <div class="skuMain">
          <div class="skuBox" v-show="showSku">
            <top-sku
              :productInfo="productInfo"
              :productModelPrice="productModelPrice"
            ></top-sku>
            <sku-list
              :listClassModels="productInfo.ListClassModels"
              @selectItem="selectItem"
              :listViewProductModelPriceOrIMG="listViewProductModelPriceOrIMG"
              :productModelPrice="productModelPrice"
            ></sku-list>
            <purchase
              :sellNumber="productInfo.SellNumber"
              :inventories="inventories"
              ref="headerChild"
            ></purchase>
            <div :class="inventories ? 'sub' : 'dis'" @click="submit">
              {{ $t.detailsInterpret.submit }}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import TopSku from "./components/TopSku";
import SkuList from "./components/SkuList";
import Purchase from "./components/Purchase";
import { mapGetters, mapState } from "vuex";
export default {
  props: ["type", "showSku"],
  components: {
    TopSku,
    SkuList,
    Purchase
  },
  name: "Sku",
  data() {
    return {
      listViewProductModelPriceOrIMG: [], // 组合规格价格列表
      productModelPrice: {}, // 当前使用规格对象
      inventories: 0 //当前使用规格库存
    };
  },
  computed: {
    ...mapGetters(["isLogging"]),
    ...mapState({
      productInfo: state => state.details.productInfo
    })
  },
  watch: {
    productInfo() {
      this.listViewProductModelPriceOrIMG = this.orderByModel();
      this.getProductModelPrice();
      this.getInventory();
    }
  },
  mounted() {},
  methods: {
    orderByModel() {
      var models = [];
      var pcmodelid = "";
      var ListViewProductModelPriceOrIMG = [];

      for (var index in this.productInfo.ListClassModels) {
        models.push(
          this.productInfo.ListClassModels[index].SonClassModels[0].PCModelId
        );
      }
      pcmodelid = models.join("|");

      ListViewProductModelPriceOrIMG = this.$commonMethod.deepClone(
        this.productInfo.ListViewProductModelPriceOrIMG
      );
      ListViewProductModelPriceOrIMG.forEach((item, index) => {
        if (item.PCModelId == pcmodelid) {
          ListViewProductModelPriceOrIMG.splice(index, 1);
          ListViewProductModelPriceOrIMG.unshift(item);
        }
      });

      return ListViewProductModelPriceOrIMG;
    },
    getInventory() {
      let pCModelIdList = this.productModelPrice.PCModelId;
      var models = this.listViewProductModelPriceOrIMG.find(
        m => m.PCModelId == pCModelIdList
      );
      if (models) {
        this.inventories = models.Inventory;
      } else {
        this.inventories = 0;
      }
      this.$bus.$emit("resetPurchase", true);
    },
    hideSku() {
      this.$emit("hideSku");
    },
    // 初始化获取当前使用规格
    getProductModelPrice() {
      if (
        !this.listViewProductModelPriceOrIMG ||
        this.listViewProductModelPriceOrIMG.length === 0
      ) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: "产品没有产品价格集合",
          status: 400
        });
        return false;
      }
      if (
        !this.productInfo.ListClassModels ||
        this.productInfo.ListClassModels.length === 0
      ) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: "产品没有规格",
          status: 400
        });
        return false;
      }
      this.initProductModelPrice();
      console.log(this.productModelPrice);
      if (!this.productModelPrice) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: "商品价格找不到对应的产品价格集合",
          status: 400
        });
        return false;
      }
      if (this.productModelPrice) {
        let pcModelIdList = this.productModelPrice.PCModelId.split("|");
        if (pcModelIdList.length !== this.productInfo.ListClassModels.length) {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: "sku类与此商品价格sku长度不一致,请联系管理员",
            status: 400
          });
          return false;
        }
      }
      // 判断当前使用的sku在sku类上是否存在
      this.productItemSku();
    },
    // 判断当前使用的sku在sku类上是否存在
    productItemSku() {
      let { productModelPrice, productInfo } = this;
      let listClassModels = productInfo.ListClassModels;
      let isSku = false;
      listClassModels.forEach(item => {
        isSku = false;
        // 判断是否有子类
        if (item.SonClassModels && item.SonClassModels.length > 0) {
          item.SonClassModels.forEach(newItem => {
            if (productModelPrice.PCModelId.indexOf(newItem.PCModelId) !== -1) {
              isSku = true;
            }
          });
        } else {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: item.ModelName + "sku没有子类,请联系管理员",
            status: 400
          });
          // return false
        }
      });
      if (!isSku) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: "商品价格sku在sku类里找不到,请联系管理员",
          status: 400
        });
        return false;
      }
    },
    // 判断当前具体属性是否存在sku
    isItemSku(aa) {
      console.log(aa);
      return aa;
    },
    // item当前sku，pCModelId当前选择的sku值，index当前sku类下标20200925
    selectItem(item, pCModelId, index) {
      let pCModelIdList = this.productModelPrice.PCModelId;
      let newPCModelIdList = ""; // 当前选择的sku价格集合字符串
      item.SonClassModels.forEach(newItem => {
        // 替换sku价格字符串
        if (pCModelIdList.indexOf(newItem.PCModelId) !== -1) {
          let reg = RegExp(newItem.PCModelId, "g");
          newPCModelIdList = pCModelIdList.replace(reg, pCModelId);
          newPCModelIdList = this.sukSort(newPCModelIdList);
        }
      });
      if (newPCModelIdList === pCModelIdList) {
        return false;
      }
      let productModelPriceList = this.listViewProductModelPriceOrIMG.filter(
        priceOrIMGItem => {
          if (newPCModelIdList === this.sukSort(priceOrIMGItem.PCModelId)) {
            return priceOrIMGItem;
          }
        }
      );
      if (productModelPriceList.length > 0) {
        this.productModelPrice = productModelPriceList[0];
      } else {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: productModelPriceList[0].PCModelId + "在商品sku价格集合找不到",
          status: 400
        });
        return false;
      }
      this.getInventory();
      // let productSpringList = this.productModelPrice.PCModelId.split('|')
      // // console.log(productSpringList)
      // productSpringList.splice(index, 1, pCModelId)
      // // 当前使用组合规格
      // this.productModelPrice = this.listViewProductModelPriceOrIMG.filter(
      //   newItem => {
      //     if (newItem.PCModelId === productSpringList.join('|')) {
      //       // console.log(newItem);
      //       return newItem
      //     }
      //   }
      // )[0]
      // console.log(this.productModelPrice)
    },
    // suksort排序20200925
    sukSort(newPCModelIdList) {
      if (newPCModelIdList.indexOf("|") !== -1) {
        // sku价格集合字符串排序
        let newPCModelIdListM = newPCModelIdList.split("|").sort();
        return newPCModelIdListM.join("|");
      } else {
        return newPCModelIdList;
      }
    },
    submit() {
      if (this.inventories) {
        if (this.type === "addShop") {
          this.shopCar();
        } else {
          this.nowBuy();
        }
      } else {
        this.$commonMethod.showNotify({
          type: "error",
          titleMsg: this.$t.detailsInterpret.inventoryText
        });
      }
    },
    // 获取当前的ProductModelStr选择的具体规格字符串值
    getProductModelStr() {
      let stringPCModelId = []; // 规则id
      let pCModelId = this.productModelPrice.PCModelId;
      if (pCModelId.indexOf("|") !== -1) {
        stringPCModelId = pCModelId.split("|");
      } else {
        stringPCModelId = [pCModelId];
      }
      let productModelStrlist = []; // 规则列表
      let listClassModels = this.productInfo.ListClassModels; // 规格价格集合
      console.log(listClassModels);
      stringPCModelId.forEach((item, index) => {
        listClassModels.forEach((newItem, newIndex) => {
          newItem.SonClassModels.forEach(sonItem => {
            if (parseInt(sonItem.PCModelId) === parseInt(item)) {
              productModelStrlist.push(sonItem.ModelName);
            }
          });
        });
      });
      return productModelStrlist.join("|");
    },
    // 立即购买
    nowBuy() {
      console.log(this.productInfo);
      let list = [];
      let item = {
        ProductNumber: this.$route.query.ProductNumber,
        Number: this.$refs.headerChild._data.newSellNumber,
        ShopId: this.$route.query.ShopId,
        ProductModel: this.productModelPrice.PCModelId,
        ProductModelStr: this.getProductModelStr()
          ? this.getProductModelStr()
          : this.productModelPrice.ProductModelStr
      };
      list.push(item);
      this.$emit("hideSku");
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "order",
        name: "order",
        query: [
          {
            name: "list",
            value: JSON.stringify(list)
          }
        ]
      });
    },
    // 添加到购物车
    shopCar() {
      this.$commonMethod.showLoading();
      let param = {
        ShopId: this.$route.query.ShopId,
        ProductNumber: this.$route.query.ProductNumber,
        ProductModel: this.productModelPrice.PCModelId,
        ShopMoney: this.productModelPrice.CurrentPrice,
        Number: this.$refs.headerChild._data.newSellNumber,
        ProductModelStr: this.getProductModelStr()
          ? this.getProductModelStr()
          : this.productModelPrice.ProductModelStr
      };
      // console.log(param)
      // if (param) {
      //   return false
      // }

      this.$api.shopCar
        .addShopCar(param)
        .then(res => {
          console.log(res);
          this.$commonMethod.hideLoading();
          this.$emit("hideSku");
          this.$commonMethod.showToast({
            type: "Success",
            titleMsg: res.message
          });
          // 透传消息
          this.$commonEnv.commonAction("transmission", {
            msg: "Add Success",
            status: 601
          });
        })
        .catch(error => {
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    },
    // 初始化获取当前使用规格
    initProductModelPrice() {
      let listClassModels = this.productInfo.ListClassModels;
      if (this.listViewProductModelPriceOrIMG.length == 0) {
        this.productModelPrice = this.listViewProductModelPriceOrIMG[0];
      } else {
        var SonClassIds = [];
        for (const parent of listClassModels) {
          SonClassIds.push(parent.SonClassModels[0].PCModelId);
        }
        //console.log(SonClassIds);
        for (const product of this.listViewProductModelPriceOrIMG) {
          var spliter = product.PCModelId.split("|");
          var firstNum = 0;
          if (parseInt(spliter[0])) {
            firstNum = parseInt(spliter[0]);
          }
          var secondNum = 0;
          if (parseInt(spliter[1])) {
            secondNum = parseInt(spliter[1]);
          }
          // console.log(firstNum);
          // console.log(secondNum);
          if (!SonClassIds[1]) {
            //单个参数
            if (firstNum == SonClassIds[0]) {
              this.productModelPrice = product;
              break;
            }
          } else {
            //两个参数
            if (firstNum == SonClassIds[0] && secondNum == SonClassIds[1]) {
              this.productModelPrice = product;
              break;
            } else if (
              secondNum == SonClassIds[1] &&
              firstNum == SonClassIds[0]
            ) {
              this.productModelPrice = product;
              break;
            }
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.skuDiv {
  position: relative;
  z-index: 101;
}
.sku {
  width: 100%;
  position: fixed;
  z-index: 102;
  bottom: 0;
  left: 0;
  right: 0;
  // height: 100vh;
  .obscuration {
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.6;
    -webkit-opacity: 0.6;
    z-index: 103;
  }
  .skuMain {
    z-index: 104;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #ffffff;
    .skuBox {
      max-height: 75vh;
      position: relative;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      margin-bottom: env(safe-area-inset-bottom);
      ::-webkit-scrollbar {
        display: none;
      }
      .sub {
        width: 88%;
        margin: 0 auto;
        color: #ffffff;
        background-color: #48d58e;
        border-radius: 25px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        margin-bottom: 20px;
      }
      .dis {
        width: 88%;
        margin: 0 auto;
        color: #ffffff;
        background-color: #a7a7a7;
        border-radius: 25px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  // .fullscreen-enter {
  //   animation: fullscreen-dialog-fade-in 0.5s ease;
  // }
  // .fullscreen-leave {
  //   animation: fullscreen-dialog-fade-out 0.5s ease forwards;
  // }
  //过渡动画
  .fullscreen-enter-active {
    animation: fullscreen-dialog-fade-in 0.6s ease;
    -webkit-animation: fullscreen-dialog-fade-in 0.6s ease;
  }
  .fullscreen-leave-active {
    animation: fullscreen-dialog-fade-out 0.6s ease;
    -webkit-animation: fullscreen-dialog-fade-out 0.6s ease;
  }

  @keyframes fullscreen-dialog-fade-in {
    0% {
      transform: translate3d(0, 85vh, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes fullscreen-dialog-fade-out {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 90vh, 0);
      opacity: 1;
    }
  }
  .obscurationClass-enter-active {
    animation: obscurationClass-fade-in 0.8s ease;
    -webkit-animation: fobscurationClass-fade-in 0.8s ease;
  }
  .obscurationClass-leave-active {
    animation: obscurationClass-out 0.8s ease;
    -webkit-animation: obscurationClass-fade-out 0.8s ease;
  }
  @keyframes obscurationClass-fade-in {
    from {
      opacity: 0;
      -webkit-opacity: 0;
    }
    to {
      opacity: 0.6;
      -webkit-opacity: 0.6;
    }
  }
  @keyframes obscurationClass-fade-out {
    from {
      opacity: 0.6;
      -webkit-opacity: 0.6;
    }
    to {
      opacity: 0;
      -webkit-opacity: 0;
      display: none;
    }
  }
}
</style>
