<template>
  <div class="hotSale">
    <div class="hotBox">
      <div class="top" @click="getShopId(shopInfo.ShopId)">
        <van-image
          class="userImgIcon"
          :src="shopInfo.ShopHeadPhoto && imgUrlFilter(shopInfo.ShopHeadPhoto)"
        >
          <template v-slot:error>
            <img class="ImgIcon" src="~assets/img/userManage/img_logo@2x.png" />
          </template>
        </van-image>

        <section class="topInfo">
          <p class="topInfoTitle">{{ shopInfo.ShopName }}</p>
          <p class="topInfoP">{{ shopInfo.ShopDescription }}</p>
        </section>
        <img
          v-if="shopInfo.IsFollow === 1"
          class="attention"
          src="~assets/img/details/icon_detail_like_select@2x.png"
          @click.stop="addFollowShop(0)"
        />
        <img
          v-else
          class="attention"
          src="~assets/img/details/icon_detail_like@2x.png"
          @click.stop="addFollowShop(1)"
        />
      </div>
      <activity-list :listProduct="productByShop"></activity-list>
    </div>
  </div>
</template>
<script>
import ActivityList from "@components/activity/ActivityList";
export default {
  name: "HotSale",
  props: ["shopInfo", "productByShop"],
  components: {
    ActivityList,
  },
  data() {
    return {
      listProduct: {},
    };
  },
  watch: {},
  methods: {
    imgUrlFilter(value) {
      // console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value);
    },
    // 关注商铺
    addFollowShop(IsFollow) {
      this.$emit("addFollowShop", IsFollow);
    },
    // 跳转商铺
    getShopId(ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "store",
        name: "store",
        query: [
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.hotSale {
  width: 100%;
  padding-top: 10px;
  //background-color: #f6f6f6;
  background-color: #f9f9f9;
  ::v-deep .activityList {
    //background-color: #f9f9f9;
    margin-top: 0;
  }
  .hotBox {
    padding-bottom: 10px;
    //padding: 0 24px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    width: 92%;
    .top {
      align-items: center;
      -webkit-align-items: center;
      padding: 25px 25px 20px 25px;
      @include publicFlex;
      .userImgIcon {
        width: 50px;
        height: 50px;
        margin-right: 17px;
        object-fit: cover;
        -webkit-object-fit: cover;
        .ImgIcon {
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
        ::v-deep .van-image__img {
          width: 50px;
          height: 50px;
          position: relative;
          object-fit: cover;
        }
        ::v-deep .van-image__error {
          width: 50px;
          height: 50px;
          position: relative;
          object-fit: cover;
        }
      }
      .topInfo {
        flex: 1;
        -webkit-flex: 1;
        .topInfoTitle {
          font-size: 16px;
          color: #000000;
          font-weight: bold;
          margin: 0;
        }
        .topInfoP {
          font-size: 13px;
          color: #a7a7a7;
          margin-top: 8px;
          margin-bottom: 0;
        }
      }
      .attention {
        width: 31px;
        height: 31px;
      }
    }
  }
}
</style>
