<template>
  <div class="detailsSwiper">
    <van-swipe
      ref="detailSwiper"
      @change="onChange"
      :loop="false"
      indicator-color="#01aaa3"
      :show-indicators="currentIndex == 0 && videoUrl ? false : true"
    >
      <van-swipe-item v-if="videoUrl">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          @canplaythrough="hiddnLoading1"
          @ready="hiddnLoading2"
        ></video-player>

        <div class="loading-overlay" v-if="isLoading">
          <van-loading size="50px" color="#48d58e"></van-loading>
        </div>

        <div class="video-progress-outer">
          <div
            class="video-progress-inner"
            :style="{ width: currentProgress + '%' }"
          ></div>
        </div>
        <div class="slides-pager-corner1">
          <van-button class="slides-pager-fullscreen" @click="onFullScreen()">
            <van-icon name="expand-o" size="20" />
          </van-button>
        </div>
        <div class="slides-pager-corner2">
          <van-button class="slides-pager-image" @click="toImage($event)">
            <van-icon name="photo" />
          </van-button>
        </div>
      </van-swipe-item>

      <van-swipe-item
        v-for="(item, index) in swiperList"
        :key="index"
        @click="imagePreview(index)"
      >
        <img v-lazy="item && imgUrlFilter(item)" class="swipeImg" />

        <div class="slides-pager-corner3" v-if="videoUrl">
          <van-button class="slides-pager-video" @click="toVideo($event)">
            <van-icon name="video" />
          </van-button>
        </div>
      </van-swipe-item>

      <template #indicator>
        <div class="custom-indicator">
          {{ currentIndex + 1 }} /
          {{ videoUrl ? swiperList.length + 1 : swiperList.length }}
        </div>
      </template>
    </van-swipe>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem, Lazyload, Icon, Button,Loading } from "vant";
import { isProduction, isHave } from "@environment/type";

Vue.use(Swipe)
  .use(SwipeItem)
  .use(Icon)
  .use(Lazyload)
  .use(Button)
  .use(Loading);
export default {
  name: "DetailsSwiper",
  props: ["swiperList", "videoUrl"],
  data() {
    return {
      currentIndex: 0,
      currentVideo: null,
      currentProgress: 0,
      playerOptions: {},
      isLoading: true
    };
  },
  watch: {
    swiperList() {
      this.swiperList = this.swiperList;
    }
  },
  methods: {
    onloadVideos() {
      this.playerOptions = {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: this.videoUrl // url地址
          }
        ],
        poster: require("@/assets/img/other/register/img_logo@2x.png"), // 你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: this.$t.meta.videoError, // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controls: false,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: isProduction() ? false : true // 全屏按钮
        }
      };
      setTimeout(() => {
        if (this.videoUrl) {
          this.currentVideo = document.getElementsByClassName("vjs-tech")[0];
          this.currentVideo.addEventListener("timeupdate", () => {
            this.currentProgress = Math.floor(
              (100 / this.currentVideo.duration) * this.currentVideo.currentTime
            );
            if (this.currentVideo.currentTime > 0.1) {
              this.isLoading = false;
            }
          });
        }
      }, 500);
    },
    hiddnLoading1() {
      this.isLoading = false;
    },
    hiddnLoading2() {
      let wechatH5 = isHave("MicroMessenger");
      // this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
      //   msg: JSON.stringify(navigator.userAgent),
      //   status: 200
      // });
      if (wechatH5) {
        this.isLoading = false;
      }
    },
    onFullScreen() {
      if (isProduction()) {
        this.currentVideo.webkitEnterFullscreen();
      } else {
        this.currentVideo.requestFullscreen();
      }
    },
    toImage(e) {
      e.stopPropagation();
      this.$refs["detailSwiper"].swipeTo(1, { immediate: true });
    },
    toVideo(e) {
      e.stopPropagation();
      this.$refs["detailSwiper"].swipeTo(0, { immediate: true });
    },
    onChange(index) {
      this.currentIndex = index;
      if (this.videoUrl && this.currentVideo) {
        if (index != 0) {
          this.currentVideo.pause();
        } else {
          this.currentVideo.play();
        }
      }
    },
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    // 预览图片
    imagePreview(index) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.picturePreview, {
        pictureList: this.swiperList,
        startPosition: index
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.detailsSwiper ::v-deep {
  position: relative;
  .custom-indicator {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    padding: 3px 8px;
    border-radius: 100px;
    line-height: 18px;
    font-size: 14px;
  }
  .vjs-big-play-button {
    border-radius: 50%;
    width: 2em;
    height: 2em !important;
    line-height: 65px !important;
    left: 55%;
  }
  .loading-overlay {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    img {
      width: 30%;
      height: 30%;
      object-fit: contain;
    }
  }
  .video-progress-outer {
    z-index: 999;
    position: absolute;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 3px;

    .video-progress-inner {
      min-width: 0%;
      max-width: 100%;
      transition: width 0.1s;
      white-space: nowrap;
      overflow: hidden;
      height: 100%;
      width: 0%;
      background: rgb(10, 199, 174);
    }
  }

  .slides-pager-corner1 {
    & {
      position: absolute;
      bottom: 70px;
      left: 20px;
    }

    .slides-pager-fullscreen {
      color: #fff;
      line-height: 1;
      height: 35px;
      width: 35px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      text-align: center;
    }
  }

  .slides-pager-corner2 {
    & {
      position: absolute;
      bottom:20px;
      left: 20px;
    }

    .slides-pager-image {
      color: #fff;
      line-height: 1;
      height: 35px;
      width: 35px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      text-align: center;
    }
  }

  .slides-pager-corner3 {
    & {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }

    .slides-pager-video {
      color: #fff;
      line-height: 1;
      height: 35px;
      width: 35px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      text-align: center;
    }
  }

  .video-player {
    height: 100%;
    .video-js {
      height: 100%;
      width: 100%;
    }
  }
  .van-swipe__track {
    height: 80vw;
    overflow: hidden;
  }
  .van-swipe-item {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .swipeImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-object-fit: cover;
  }
  .van-swipe__indicators {
    bottom: 24px;
  }
  .van-swipe__indicator {
    width: 8px;
    height: 8px;
  }
}
</style>
