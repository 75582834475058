<template>
  <div class="details">
    <div class="swiperBox">
      <details-swiper
        :swiperList="swiperList"
        :videoUrl="productVideo"
        ref="detailsSwiper"
      ></details-swiper>
      <details-title
        :productInfo="productInfo"
        :shopInfo="shopInfo"
        @addFavoriteProduct="addFavoriteProduct"
      ></details-title>
    </div>
    <!-- 产品信息 -->
    <product-info :productInfo="productInfo"></product-info>
    <!-- 产品描述 -->
    <spec :productInfo="productInfo"></spec>
    <!-- 推荐热销类型 -->
    <hot-sale
      :shopInfo="shopInfo"
      :productByShop="productByShop"
      @addFollowShop="addFollowShop"
    ></hot-sale>
    <!-- 推荐商品 -->
    <product-list
      :productList="productList"
      :isPlaceholder="isPlaceholder"
    ></product-list>
    <div class="shopBox">
      <van-button
        plain
        round
        type="default"
        @click="getShopId(shopInfo.ShopId)"
        >{{ $t.meta.viewShop }}</van-button
      >
    </div>
    <section class="btBox" v-if="productState == 1">
      <button class="addShop" @click="getSku('addShop')">
        {{ $t.detailsInterpret.addcart }}
      </button>
      <button class="buy" @click="getSku('buy')">
        {{ $t.detailsInterpret.buy }}
      </button>
    </section>
    <section class="btBox" v-else-if="productState == 0">
      <button class="offline">{{ $t.cart.productOff }}</button>
    </section>
    <section class="btBox" v-else>
      <button class="loading">{{ $t.loading }}</button>
    </section>
    <!-- 规格 -->
    <!-- <van-popup v-model="showSku" position="bottom" > -->
    <sku :type="type" :showSku="showSku" @hideSku="hideSku"></sku>
    <!-- </van-popup> -->

    <van-action-sheet
      v-model="openAppShow"
      :actions="actions"
      @select="onSelect"
    />
  </div>
</template>
<script>
import DetailsSwiper from "./components/DetailsSwiper";
// import LogoList from './components/LogoList'
import DetailsTitle from "./components/DetailsTitle";
import ProductInfo from "./components/ProductInfo";
import Spec from "./components/Spec";
import HotSale from "./components/HotSale";
import ProductList from "@components/common/ProductList";
import Sku from "@main/sku/Sku";
import { mapGetters, mapState, mapMutations } from "vuex";
import { SET_PRODUCTINFO } from "@/store/mutation-types";
import Vue from "vue";
import { isAPP } from "@/plugins/environment/type.js";
import { Sticky, ActionSheet, Popup, Image, Button } from "vant";

Vue.use(Sticky).use(ActionSheet).use(Popup).use(Image).use(Button);
export default {
  name: "Details",
  components: {
    DetailsSwiper,
    DetailsTitle,
    ProductInfo,
    Spec,
    HotSale,
    ProductList,
    Sku,
  },
  data() {
    return {
      openAppShow: false, // 显示打开app
      actions: [],
      showSku: false,
      type: "addShop",
      isPlaceholder: false, // 是否显示没有数据logo
      shopId: "", // 商品
      productNumber: "", // 商品id
      productList: [], // 推荐商品
      productByShop: [], // 店铺推荐商品
      isApp: isAPP(), // 当前环境
      swiperList: [], // 轮播图
      productVideo: null, // 商品视频
      productState: null, // 商品状态（判断上下架）
      listClassModels: [], // 产品规格
      shopInfo: {}, // 店铺信息
    };
  },
  watch: {
    $route(to, from) {
      if (to.query !== from.query) {
        const { ShopId, ProductNumber, BackHome } = this.$route.query;
        this.shopId = ShopId;
        this.productNumber = ProductNumber;
        this.loadProductInfo();
      }
    },
  },
  computed: {
    ...mapGetters(["isLogging"]),
    ...mapState({
      productInfo: (state) => state.details.productInfo,
    }),
  },
  created() {
    const { BackHome } = this.$route.query;
    if (BackHome) {
      this.$route.meta.backHome = true;
    } else {
      this.$route.meta.backHome = false;
    }
  },
  destroyed() {
    this.SET_PRODUCTINFO({});
    console.log("销毁details" + this.productInfo);
  },
  beforeMount() {
    const { ShopId, ProductNumber } = this.$route.query;
    this.shopId = ShopId;
    this.productNumber = ProductNumber;
  },
  mounted() {
    this.loadProductInfo();
    // 打开app
    if (this.isApp) {
      // let { token } = JSON.parse(localStorage.getItem('auth')) || {}
      // console.log()
      // console.log(localStorage.getItem('isOpenApp'))
      // if (parseInt(localStorage.getItem('isOpenApp')) === 1) {
      //   this.startApp()
      // } else {
      //   this.openApp()
      // }
    }
  },
  methods: {
    ...mapMutations([SET_PRODUCTINFO]),
    videoUrlFilter(value) {
      return this.$api.formatVideoUrl(value);
    },
    onSelect(item) {
      console.log(item);
      // alert(item.url)
      window.location.href = item.url;
      this.openAppShow = false;
    },
    // 直接打开app
    startApp() {
      let appStr =
        "vancheenc://startProductDetail?ProductNumber=" +
        this.productNumber +
        "&ShopId=" +
        this.shopId;
      window.location.href = appStr;
    },
    // 20200529打开app
    openApp() {
      this.openAppShow = true;
      let appStr =
        "vancheenc://startProductDetail?ProductNumber=" +
        this.productNumber +
        "&ShopId=" +
        this.shopId;
      console.log(appStr);
      localStorage.setItem("isOpenApp", 1);
      let actions = [
        {
          name: "Open APP",
          color: "#07c160",
          url: appStr,
        },
        {
          name: "Download APP",
          color: "#07c160",
          url: "http://download.vancheen.com/AppDownload/C_AppDownload",
        },
      ];
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        // window.location.href = appStr// ios app协议
        // window.setTimeout(function () {
        //   window.location.href = 'http://download.vancheen.com/AppDownload/C_AppDownload'
        // }, 2000)
        console.log("ios");
        // alert('ios')
      }
      if (navigator.userAgent.match(/android/i)) {
        // window.location.href = appStr// android app协议
        // window.setTimeout(function () {
        //   window.location.href = 'http://download.vancheen.com/AppDownload/C_AppDownload'// android 下载地址
        // }, 2000)
        console.log("安卓");
        // alert('安卓')
      }
      this.actions = actions;
    },
    // 获取搜索页数据
    loadProductInfo() {
      this.$commonMethod.showLoading();
      let param = {
        ShopId: this.shopId,
        ProductNumber: this.productNumber,
      };
      this.$api.product
        .loadProductInfo(param)
        .then((res) => {
          const data = res.data;
          this.$commonMethod.hideLoading();
          this.SET_PRODUCTINFO(data.ProductInfo);
          this.swiperList = data.ProductInfo.ProductPhoto.split("|");
          this.productVideo = this.videoUrlFilter(
            data.ProductInfo.ProductVideo
          );
          this.productByShop = data.ProductByShop;
          this.productList = data.NewProduct;
          this.shopInfo = data.ShopInfo;
          this.productState = data.ProductInfo.Status;
          this.$nextTick((_) => {
            this.$refs.detailsSwiper.onloadVideos();
          });
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 显示规格
    getSku(type) {
      // console.log()
      if (!this.isLogin()) {
        return;
      }
      this.showSku = true;
      this.type = type;
    },
    // 检查是否登录
    isLogin() {
      let login = true;
      if (!this.isLogging) {
        login = false;
        this.$router.push({
          path: "auth/login",
          query: { redirect: this.$route.fullPath, IsDetail: true },
        });
      }
      return login;
    },
    // 关注店铺
    addFollowShop(IsFollow) {
      if (!this.isLogin()) {
        return;
      }
      // 1：关注，0：取消关注
      this.$commonMethod.showLoading();
      let param = {
        ShopId: this.shopId,
        IsFollow: IsFollow,
      };
      this.$api.follow
        .addFollowShop(param)
        .then((res) => {
          const data = res;
          this.$commonMethod.hideLoading();
          this.$commonMethod.showToast({
            type: "success",
            titleMsg: data.message,
          });
          if (this.shopInfo.IsFollow === 1) {
            this.shopInfo.IsFollow = 0;
          } else {
            this.shopInfo.IsFollow = 1;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 添加产品到我的喜爱
    addFavoriteProduct(IsFavorite) {
      if (!this.isLogin()) {
        return;
      }
      // 1：喜欢，0： 取消喜欢
      this.$commonMethod.showLoading();
      let param = {
        ShopId: this.shopId,
        IsFavorite: IsFavorite,
        ProductNumber: this.productNumber,
      };
      this.$api.follow
        .addFavoriteProduct(param)
        .then((res) => {
          this.$commonMethod.hideLoading();
          // this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          //   msg: res.message,
          //   status: res.StatusCode
          // })
          this.$commonMethod.showToast({
            type: "success",
            titleMsg: res.message,
          });
          if (this.productInfo.IsFavorite === 1) {
            this.productInfo.IsFavorite = 0;
          } else {
            this.productInfo.IsFavorite = 1;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 跳转商铺
    getShopId(ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "store",
        name: "store",
        query: [
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
    // 关闭规格页面
    hideSku() {
      this.showSku = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.details {
  width: 100%;
  .swiperBox {
    position: relative;
    width: 100%;
  }
  .shopBox {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 15px;
    .van-button {
      width: 90%;
      color: #999;
    }
  }
  .btBox {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    bottom: 0;
    margin-top: 25px;
    background-color: #f6f6f6;
    border-top: 0.1rem solid #e5e5e5;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding-left:4%;
    padding-right:4%;
    padding-top: 2%;
    padding-bottom: calc(2% + env(safe-area-inset-bottom));
    @include publicFlex;
    .addShop {
      outline: none;
      margin: 0;
      padding: 0;
      border: none;
      width: 44.5vw;
      background-color: #ffffff;
      color: #48d58e;
      border-radius: 30px;
      height: 42.5px;
      font-size: 17px;
    }
    .buy {
      outline: none;
      margin: 0;
      padding: 0;
      border: none;
      width: 44.5vw;
      background-color: #48d58e;
      color: #ffffff;
      border-radius: 30px;
      height: 42.5px;
      font-size: 17px;
    }
    .offline {
      outline: none;
      margin: 0;
      padding: 0;
      border: none;
      width: 100%;
      background-color: transparent;
      color: #ff5852;
      height: 42.5px;
      font-size: 17px;
      font-weight: bold;
    }
    .loading {
      outline: none;
      margin: 0;
      padding: 0;
      border: none;
      width: 100%;
      background-color: transparent;
      color: #48d58e;
      height: 42.5px;
      font-size: 17px;
      font-weight: bold;
    }
  }
}
</style>
