<template>
  <div class="topSku">
    <van-image
      class="productImg"
      :src="productInfo.ProductPhoto && imgUrlFilter(productInfo.ProductPhoto)"
    >
      <template v-slot:error>
        <img class="ImgIcon" src="~assets/img/other/empty.gif" />
      </template>
    </van-image>
    <section class="titleInfo">
      <!-- <p class="price">$1.99~$31.6</p> -->
      <p class="price">${{newProductModelPrice.CurrentPrice}}</p>
      <!-- <p class="sell">{{productInfo.Sales}} {{$t.detailsInterpret.detailsSold}}</p> -->
      <p class="inventory">{{newProductModelPrice.Inventory}} {{$t.detailsInterpret.inventory}}</p>
      <div class="specList">
        <span class="specTitle">{{$t.detailsInterpret.pleaseSelect}}</span>
        <span
          class="specItem"
          v-for="item in productInfo.ListClassModels"
          :key="item.PCModelId"
        >{{item.ModelName}}</span>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "TopSku",
  props: ["productInfo", "productModelPrice"],
  data() {
    return {
      // newProductModelPrice: this.productModelPrice,
      // currentPrice: this.productInfo.CurrentPrice
    };
  },
  computed: {
    newProductModelPrice() {
      return this.productModelPrice;
    },
    currentPrice() {
      return this.productInfo.CurrentPrice;
    }
  },
  // watch: {
  //   productModelPrice () {
  //     this.newProductModelPrice = this.productModelPrice
  //   }
  // },
  mounted() {},
  methods: {
    imgUrlFilter(value) {
      // console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value.split("|")[0]);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.topSku {
  width: 88%;
  margin: 0 auto;
  margin-top: 20px;
  * {
    margin: 0;
    padding: 0;
  }
  @include publicFlex;
  .productImg {
    width: 90px;
    height: 90px;
    margin-right: 20px;
    object-fit: cover;
    .ImgIcon {
      width: 90px;
      height: 90px;
      object-fit: cover;
    }
    ::v-deep .van-image__img {
      width: 90px;
      height: 90px;
      object-fit: cover;
    }
    ::v-deep .van-image__error {
      width: 90px;
      height: 90px;
      object-fit: cover;
    }
  }
  .titleInfo {
    flex: 1;
    -webkit-felx: 1;
    .price {
      font-size: 16px;
      color: #ff5852;
      font-weight: bold;
    }
    .sell {
      margin-top: 12px;
      font-size: 12px;
      color: #a7a7a7;
    }
    .inventory{
      margin-top: 12px;
      font-size: 12px;
      color: #a7a7a7;
    }
    .specList {
      margin-top: 10px;
      width: 100%;
      font-size: 12px;
      color: #000000;
      .specTitle {
        margin-right: 5px;
      }
      .specItem {
        margin-right: 5px;
      }
    }
  }
}
</style>
